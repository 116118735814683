<script setup lang="ts">
import { useField } from 'vee-validate';

// Props
const modelValue = defineModel<boolean>();

const props = defineProps<{
  name: string;
  label?: string;
  disabled?: boolean;
}>();

// Form
const { setValue } = useField<boolean>(props.name, undefined, {
  initialValue: modelValue.value,
  valueProp: modelValue.value,
});

// Lifecycle
watch(
  () => modelValue.value,
  (newValue) => {
    setValue(newValue);
  },
);
</script>
<template>
  <label class="flex flex-row gap-2 items-center justify-start" :class="[disabled ? 'cursor-not-allowed' : 'cursor-pointer']">
    <div>
      <checkbox-root
        v-model="modelValue"
        :disabled="disabled"
        :class="[modelValue ? 'border-primary-500' : 'border-gray-200', disabled ? '!bg-gray-100 cursor-not-allowed' : 'hover:bg-gray-50 ']"
        class="flex h-4 w-4 appearance-none items-center justify-center rounded border outline-none bg-white"
      >
        <checkbox-indicator :class="[modelValue ? 'bg-primary-500' : 'bg-white']" class="h-full w-full flex items-center justify-center">
          <ui-icon name="Check" class="h-3.5 w-3.5" :class="[modelValue ? 'stroke-white' : 'stroke-gray-700']" />
        </checkbox-indicator>
      </checkbox-root>
    </div>
    <slot>
      <span v-if="label" class="select-none text-sm" :class="[disabled ? 'text-gray-400' : 'text-gray-700']">{{ label }}</span>
    </slot>
  </label>
</template>
