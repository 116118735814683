<script setup lang="ts">
import dayjs from 'dayjs';
import { useField } from 'vee-validate';
import type { IconName } from '~/components/ui/Icon.vue';
import { type DateValue, parseDate, parseTime } from '@internationalized/date';

// Composables
const { locale } = useI18n();
const { getHeight, getTextSize, getInputColor, getSelectOpenAnimation } = useInput();
const { shortcuts } = useInputDate();

// Props
const modelValue = defineModel<{ start: Date; end: Date } | null>();
const props = withDefaults(
  defineProps<{
    name: string;
    label?: string;
    required?: boolean;
    size?: InputSize;
    leadingIcon?: IconName;
    leadingText?: string;
    trailingIcon?: IconName;
    disabled?: boolean;
    placeholder?: string;
    dateFormat?: string;
    type?: 'date' | 'datetime';
    content?: {
      direction?: 'left' | 'bottom' | 'right' | 'top';
      align?: 'start' | 'center' | 'end';
      sideOffset?: number;
    };
  }>(),
  {
    label: null,
    leadingIcon: null,
    leadingText: null,
    trailingIcon: 'ChevronDown',
    size: 'md',
    disabled: false,
    placeholder: null,
    modelValue: null,
    dateFormat: 'DD/MM/YYYY',
    type: 'datetime',
    content: () => ({
      direction: 'bottom',
      align: 'start',
      sideOffset: 4,
    }),
  },
);

// Data
const open = ref(false);
const input = ref(null);
const showTime = ref(false);
const { errorMessage, value, setValue } = useField<{ start: Date; end: Date }>(props.name, undefined, {
  initialValue: modelValue.value,
  valueProp: modelValue.value,
});
const startDateFocused = ref(false);
const startHourFocused = ref(false);
const endDateFocused = ref(false);
const endHourFocused = ref(false);

// Methods
const updateDateValue = (options: { date: DateValue; type: 'start' | 'end' }) => {
  if (!options.date) return;
  if (
    options.date.year < 2015 ||
    options.date.year > dayjs().year() ||
    dayjs(`${options.date.year}-${options.date.month}-${options.date.day}`).isSameOrAfter(dayjs())
  )
    return;

  const newDate = dayjs(`${options.date.year}-${options.date.month}-${options.date.day}`);
  const originalDate = modelValue.value[options.type];

  // Vérification des dates
  if (options.type === 'start' && modelValue.value?.end && newDate.isSameOrAfter(modelValue.value.end)) {
    return;
  }
  if (options.type === 'end' && modelValue.value?.start && newDate.isSameOrAfter(modelValue.value.start)) {
    return;
  }

  if (showTime.value) {
    modelValue.value[options.type] = newDate.set('hour', dayjs(originalDate).hour()).set('minute', dayjs(originalDate).minute()).toDate();
  } else {
    modelValue.value[options.type] = options.type === 'start' ? newDate.startOf('day').toDate() : newDate.endOf('day').toDate();
  }
};

const updateBothDateValue = (date: { start: Date; end: Date }) => {
  if (!date) return;
  const originalDate = modelValue.value;

  if (showTime.value) {
    modelValue.value = {
      start: dayjs(date.start).set('hour', dayjs(originalDate.start).hour()).set('minute', dayjs(originalDate.start).minute()).toDate(),
      end: dayjs(date.end).set('hour', dayjs(originalDate.end).hour()).set('minute', dayjs(originalDate.end).minute()).toDate(),
    };
  } else {
    modelValue.value = {
      start: dayjs(date.start).startOf('day').toDate(),
      end: dayjs(date.end).endOf('day').toDate(),
    };
  }
};

const updateTimeValue = (options: { time: any; type: 'start' | 'end' }) => {
  if (!options.time) return;
  const originalDate = modelValue.value[options.type];
  modelValue.value[options.type] = dayjs(originalDate).set('hour', options.time.hour).set('minute', options.time.minute).toDate();
};

const shortcutClick = (shortcut: { label: string; start: Date; end: Date }) => {
  modelValue.value.start = shortcut.start;
  modelValue.value.end = shortcut.end;
};

const resetTime = () => {
  if (!showTime.value && modelValue.value) {
    modelValue.value = {
      start: dayjs(modelValue.value.start).set('hour', 0).set('minute', 0).toDate(),
      end: dayjs(modelValue.value.end).set('hour', 23).set('minute', 59).toDate(),
    };
  }
};

const checkTime = () => {
  if (!modelValue.value) return;
  if (props.type === 'date') return;
  const startHour = dayjs(modelValue.value.start).format('HH:mm') === '00:00';
  const endHour = dayjs(modelValue.value.end).format('HH:mm') === '23:59';
  if (!startHour || !endHour) {
    showTime.value = true;
  }
};

// Lifecycle
watch(
  () => modelValue.value,
  (newValue) => setValue(newValue),
);

watch(
  () => modelValue.value,
  () => {
    checkTime();
  },
);
</script>
<template>
  <div class="text-gray-600 w-full">
    <popover-root v-model:open="open" class="w-full">
      <label v-if="label" :class="errorMessage ? 'text-red-300' : 'text-gray-600'" class="font-medium text-xs mb-[6px] block text-left">
        <span v-if="required" class="text-red-500">*</span>
      </label>
      <popover-trigger class="w-full">
        <slot name="trigger" :open="open">
          <div
            ref="input"
            class="px-2.5 border rounded-md flex justify-between items-center w-full bg-white"
            :class="[getHeight(size), getTextSize(size), getInputColor({ errorMessage, disabled, open })]"
          >
            <div class="flex items-center gap-2">
              <ui-icon v-if="leadingIcon" :name="leadingIcon" class="h-4 w-4 stroke-gray-600" />
              <span class="block flex-1 text-gray-600 truncate select-none">
                <span v-if="leadingText" class="text-gray-600">{{ leadingText }} : </span>
                <span v-if="value?.start && value?.end">
                  {{ dayjs(value?.start).format(dateFormat) }}
                  <span v-if="showTime" class="text-[10px]"> {{ dayjs(value?.start).format('HH:mm') }} </span>
                  {{ $t('global.date_to').toLocaleLowerCase() }}
                  {{ dayjs(value?.end).format(dateFormat) }}
                  <span v-if="showTime" class="text-[10px]"> {{ dayjs(value?.end).format('HH:mm') }} </span>
                </span>
                <span v-else>{{ placeholder }}</span>
              </span>
            </div>
            <div class="flex items-center pl-2">
              <ui-icon v-if="trailingIcon" :name="trailingIcon" class="text-gray-600 w-4" :stroke-width="2" />
            </div>
          </div>
        </slot>
      </popover-trigger>
      <popover-portal>
        <popover-content
          position-strategy="fixed"
          :side="content.direction"
          :align="content.align"
          :side-offset="content.sideOffset"
          :class="[getSelectOpenAnimation()]"
          class="bg-white z-50 min-h-[100%] shadow-md border rounded-md border-gray-200"
        >
          <div class="grid grid-cols-2">
            <div>
              <!-- Date -->
              <div class="border-b border-gray-200 p-3 flex flex-col gap-2">
                <!-- Start Date -->
                <div class="w-full flex flex-row gap-2">
                  <date-field-root
                    v-slot="{ segments }"
                    :model-value="value?.start ? parseDate(dayjs(value.start).format('YYYY-MM-DD')) : null"
                    :hour-cycle="24"
                    :locale="locale"
                    class="px-2.5 border rounded-md flex justify-start items-center w-full bg-white text-gray-600"
                    :class="[getHeight(size), getTextSize(size), getInputColor({ errorMessage, disabled, open: startDateFocused })]"
                    @update:model-value="updateDateValue({ date: $event, type: 'start' })"
                  >
                    <template v-for="item in segments" :key="item.part">
                      <date-field-input :part="item.part" @focus="startDateFocused = true" @blur="startDateFocused = false">
                        {{ item.value }}
                      </date-field-input>
                    </template>
                  </date-field-root>

                  <time-field-root
                    v-if="showTime && props.type === 'datetime'"
                    v-slot="{ segments }"
                    :hour-cycle="24"
                    :locale="locale"
                    :model-value="modelValue?.start ? parseTime(dayjs(modelValue.start).format('HH:mm')) : null"
                    class="border rounded-md bg-white text-gray-600 flex items-center justify-center px-2"
                    :class="[getHeight(size), getTextSize(size), getInputColor({ errorMessage, disabled, open: startHourFocused })]"
                    @update:model-value="updateTimeValue({ time: $event, type: 'start' })"
                  >
                    <template v-for="item in segments" :key="item.part">
                      <time-field-input v-if="item.part === 'literal'" :part="item.part">
                        {{ item.value }}
                      </time-field-input>
                      <time-field-input
                        v-else
                        :part="item.part"
                        class="rounded p-[1px]"
                        @focus="startHourFocused = true"
                        @blur="startHourFocused = false"
                      >
                        {{ item.value }}
                      </time-field-input>
                    </template>
                  </time-field-root>
                </div>

                <!-- End Date -->
                <div class="w-full flex flex-row gap-2">
                  <date-field-root
                    id="end-date"
                    v-slot="{ segments }"
                    :model-value="value?.end ? parseDate(dayjs(value.end).format('YYYY-MM-DD')) : null"
                    :hour-cycle="24"
                    :locale="locale"
                    class="px-2.5 border rounded-md flex justify-start items-center w-full bg-white text-gray-600"
                    :class="[getHeight(size), getTextSize(size), getInputColor({ errorMessage, disabled, open: endDateFocused })]"
                    @update:model-value="updateDateValue({ date: $event, type: 'end' })"
                  >
                    <template v-for="item in segments" :key="item.part">
                      <date-field-input :part="item.part" @focus="endDateFocused = true" @blur="endDateFocused = false">
                        {{ item.value }}
                      </date-field-input>
                    </template>
                  </date-field-root>

                  <time-field-root
                    v-if="showTime && props.type === 'datetime'"
                    v-slot="{ segments }"
                    :hour-cycle="24"
                    :locale="locale"
                    :model-value="modelValue?.end ? parseTime(dayjs(modelValue.end).format('HH:mm')) : null"
                    class="border rounded-md bg-white text-gray-600 flex items-center justify-center px-2"
                    :class="[getHeight(size), getTextSize(size), getInputColor({ errorMessage, disabled, open: endHourFocused })]"
                    @update:model-value="updateTimeValue({ time: $event, type: 'end' })"
                  >
                    <template v-for="item in segments" :key="item.part">
                      <time-field-input v-if="item.part === 'literal'" :part="item.part">
                        {{ item.value }}
                      </time-field-input>
                      <time-field-input
                        v-else
                        :part="item.part"
                        class="rounded p-[1px]"
                        @focus="endHourFocused = true"
                        @blur="endHourFocused = false"
                      >
                        {{ item.value }}
                      </time-field-input>
                    </template>
                  </time-field-root>
                </div>
                <nrjx-input-switch
                  v-if="props.type === 'datetime'"
                  v-model="showTime"
                  name="showTime"
                  :label="$t('explore.show_time')"
                  @update:model-value="resetTime()"
                />
              </div>
              <!-- Calendar -->
              <div>
                <nrjx-input-range-calendar :model-value="modelValue" @update:model-value="updateBothDateValue($event)" />
              </div>
            </div>
            <div class="h-full bg-gray-50 rounded p-3">
              <label class="text-sm font-semibold text-gray-900">Racourcis</label>
              <div class="space-y-2">
                <div
                  v-for="shortcut in shortcuts(modelValue)"
                  :key="shortcut.label"
                  class="w-full border border-gray-200 p-1.5 bg-white text-sm text-center rounded-md cursor-pointer text-gray-600"
                  :class="shortcut.selected ? 'border-green-500 ring-inset' : ''"
                  @click="shortcutClick(shortcut)"
                >
                  {{ shortcut.label }}
                </div>
              </div>
            </div>
          </div>
        </popover-content>
      </popover-portal>
    </popover-root>
  </div>
</template>
