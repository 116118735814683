import dayjs from 'dayjs';

export const useInputDate = () => {
  const { t } = useI18n();

  const shortcuts = (periodSelected: { start: Date; end: Date }) => {
    // Values
    const start = dayjs(periodSelected.start);
    const end = dayjs(periodSelected.end);

    return [
      {
        label: t('global.date_range.last_x_days', { value: 7 }),
        start: dayjs().subtract(6, 'day').toDate(),
        end: dayjs().toDate(),
        selected: start.isSame(dayjs().subtract(6, 'day'), 'day') && end.isSame(dayjs().toDate(), 'day'),
      },
      {
        label: t('global.date_range.last_week'),
        start: dayjs().subtract(1, 'week').startOf('week').toDate(),
        end: dayjs().subtract(1, 'week').endOf('week').toDate(),
        selected:
          start.isSame(dayjs(dayjs().subtract(1, 'week').startOf('week')), 'day') &&
          end.isSame(dayjs().subtract(1, 'week').endOf('week').toDate(), 'day'),
      },
      {
        label: t('global.date_range.current_month'),
        start: dayjs().startOf('month').toDate(),
        end: dayjs().toDate(),
        selected: start.isSame(dayjs().startOf('month').toDate(), 'day') && end.isSame(dayjs().toDate(), 'day'),
      },
      {
        label: t('global.date_range.last_month'),
        start: dayjs().subtract(1, 'month').startOf('month').toDate(),
        end: dayjs().subtract(1, 'month').endOf('month').toDate(),
        selected:
          start.isSame(dayjs().subtract(1, 'month').startOf('month'), 'day') &&
          end.isSame(dayjs().subtract(1, 'month').endOf('month').toDate(), 'day'),
      },
      {
        label: t('global.date_range.last_x_months', { value: 3 }),
        start: dayjs().subtract(3, 'month').startOf('month').toDate(),
        end: dayjs().subtract(1, 'month').endOf('month').toDate(),
        selected:
          start.isSame(dayjs().subtract(3, 'month').startOf('month'), 'day') &&
          end.isSame(dayjs().subtract(1, 'month').endOf('month'), 'day'),
      },
      {
        label: t('global.date_range.last_x_months', { value: 6 }),
        start: dayjs().subtract(6, 'month').startOf('month').toDate(),
        end: dayjs().subtract(1, 'month').endOf('month').toDate(),
        selected:
          start.isSame(dayjs().subtract(6, 'month').startOf('month'), 'day') &&
          end.isSame(dayjs().subtract(1, 'month').endOf('month'), 'day'),
      },
      {
        label: t('global.date_range.last_x_months', { value: 12 }),
        start: dayjs().subtract(12, 'month').startOf('month').toDate(),
        end: dayjs().subtract(1, 'month').endOf('month').toDate(),
        selected:
          start.isSame(dayjs().subtract(12, 'month').startOf('month'), 'day') &&
          end.isSame(dayjs().subtract(1, 'month').endOf('month'), 'day'),
      },
      {
        label: t('global.date_range.current_year'),
        start: dayjs().startOf('year').toDate(),
        end: dayjs().toDate(),
        selected: start.isSame(dayjs(dayjs().startOf('year').toDate()), 'day') && end.isSame(dayjs(dayjs().toDate()), 'day'),
      },
      {
        label: t('global.date_range.last_year'),
        start: dayjs().subtract(1, 'year').startOf('year').toDate(),
        end: dayjs().subtract(1, 'year').endOf('year').toDate(),
        selected:
          start.isSame(dayjs(dayjs().subtract(1, 'year').startOf('year')), 'day') &&
          end.isSame(dayjs().subtract(1, 'year').endOf('year').toDate(), 'day'),
      },
    ];
  };

  return {
    shortcuts,
  };
};
