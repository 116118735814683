<script lang="ts" setup>
import { onClickOutside, useElementBounding } from '@vueuse/core';
import type { Area } from '~/types/area';

// Composables
const { getInputColor } = useInput();

// Props
const areaId = defineModel<number | null>('areaId');
const areaIds = defineModel<number[]>('areaIds');
const props = defineProps<{
  disabled?: boolean;
  areaDisabled?: number[];
  energyType?: string;
  multiple?: boolean;
  side?: 'left' | 'right';
  validationButtonText?: string;
}>();

// Stores
const areaService = areaStore();

// Data
const container = ref(null);
const modal = ref(false);
const subMenusContainer = ref(null);
const subMenus = ref<Area[]>([]);
const principalMenu = ref(null);
const globalContainer = ref(null);
const button = ref<HTMLElement | null>(null);
const selectedAreas = ref<number[]>([]);

const { left, width, height, top, y, update } = useElementBounding(button);
const { width: containerWidth, height: containerHeight } = useElementBounding(container);

// Computed
const currentArea = computed(() => areaService.getArea(areaId.value));
const rootArea = computed(() => areaService.getRootAreas[0]);

const getParentsOfSelectedArea = computed(() => {
  let area = currentArea.value;
  const parents: number[] = [];
  while (area?.parent_id) {
    area = areaService.getArea(area.parent_id);
    parents.push(area.id);
  }
  return parents;
});

const getWindowHeight = computed(() => {
  return window.innerHeight;
});

const getContainerPosition = computed(() => {
  const yPosition =
    getWindowHeight.value - y.value > 300 + 32 ? `top: ${top.value + height.value}px;` : `top: ${y.value - containerHeight.value - 8}px;`;
  const xPosition = props.side === 'left' ? `left: ${left.value}px;` : `left: ${left.value + width.value - containerWidth.value}px;`;
  return yPosition + xPosition;
});

// Methods
const getArea = (areaId: number) => {
  return areaService.getArea(areaId);
};

const hasEnergyMeter = (area: Area) => {
  if (!props.energyType) return true;
  return area.meters?.some((meter) => meter.meter_type.energy_type === props.energyType);
};

const clickItem = (id: number) => {
  const area = areaService.getAreas.find((area) => area.id === id);
  if (!area) return;
  if (area.children.length > 0) {
    const hasValidChildren = area.children.some((child) => hasEnergyMeter(getArea(child.id)));
    if (!hasValidChildren) return;

    if (subMenus.value.length === 0 || !subMenus.value.find((subMenu) => subMenu.id === area.id)) {
      const parents = getParentsOfArea(id).sort((a, b) => {
        if (a.parent_id === null) return -1;
        if (b.parent_id === null) return 1;
        if (a.id === b.parent_id) return -1;
        if (b.id === a.parent_id) return 1;
        return 0;
      });
      subMenus.value = parents.filter((parent) => parent.parent_id !== null);
      subMenus.value.push(area);
    }
  } else {
    if (!props.multiple) {
      if (isAreaDisabled(id) || !hasEnergyMeter(area)) return;
      areaId.value = id;
      principalMenu.value = false;
      subMenus.value = [];
      modal.value = false;
    }
  }
};

const isAreaDisabled = (areaId: number) => {
  const area = areaService.getArea(areaId);
  if (area.children.length > 0 && !props.areaDisabled?.includes(areaId)) {
    return false;
  }
  return props.areaDisabled?.includes(areaId);
};

const clickRootItem = (id: number) => {
  if (isAreaDisabled(id) || !hasEnergyMeter(getArea(id))) return;
  areaId.value = id;
  principalMenu.value = false;
  subMenus.value = [];
  modal.value = false;
};

const selectSubMenu = (id: number) => {
  areaId.value = id;
  principalMenu.value = false;
  subMenus.value = [];
  modal.value = false;
};

const getParentsOfArea = (areaId: number) => {
  let area = areaService.getArea(areaId);
  const parents: Area[] = [];
  while (area.parent_id) {
    area = areaService.getArea(area.parent_id);
    parents.push(area);
  }
  return parents;
};

const openMenu = () => {
  update();
  modal.value = true;
  if (currentArea.value) {
    const parents = getParentsOfArea(currentArea.value.id).sort((a, b) => {
      if (a.parent_id === null) return -1;
      if (b.parent_id === null) return 1;
      if (a.id === b.parent_id) return -1;
      if (b.id === a.parent_id) return 1;
      return 0;
    });

    const parentsWithChildren = parents.filter((parent) => parent.parent_id !== null && parent.children && parent.children.length > 0);
    subMenus.value = parentsWithChildren;

    if (currentArea.value.parent_id && currentArea.value.children && currentArea.value.children.length > 0) {
      subMenus.value.push(currentArea.value);
    }
  }
};

const toggleArea = (areaId: number) => {
  if (selectedAreas.value?.includes(areaId)) {
    selectedAreas.value = selectedAreas.value?.filter((id) => id !== areaId);
  } else {
    selectedAreas.value?.push(areaId);
  }
};

const closeMenu = () => {
  if (props.multiple && selectedAreas.value?.length > 0) {
    areaIds.value = selectedAreas.value;
  }
  modal.value = false;
  subMenus.value = [];
};

onClickOutside(globalContainer, () => {
  if (modal.value) {
    closeMenu();
  }
});

watch(
  () => areaIds.value,
  () => {
    if (props.multiple && areaIds.value?.length > 0) {
      selectedAreas.value = areaIds.value;
    }
  },
  {
    immediate: true,
  },
);
</script>
<template>
  <div ref="globalContainer" class="relative select-none">
    <div ref="button" @click="openMenu">
      <slot name="button" :open="modal" :class="getInputColor({ errorMessage: '', disabled: disabled, open: modal })">
        <div
          class="border flex items-center justify-between px-2.5 rounded-md h-[32px]"
          :class="getInputColor({ errorMessage: '', disabled: disabled, open: modal })"
        >
          <div class="flex items-center justify-center gap-2">
            <ui-icon name="MapPin" class="w-4 h-4 stroke-gray-600" />
            <span class="text-sm text-gray-600">{{ currentArea?.name }}</span>
          </div>
          <ui-icon name="ChevronDown" class="w-4 h-4 stroke-gray-600" />
        </div>
      </slot>
    </div>

    <div ref="container" class="fixed z-[9999] origin-top-left" :style="getContainerPosition">
      <div class="flex gap-2" :class="[props.side === 'left' ? 'flex-row' : 'flex-row-reverse']">
        <div
          v-if="modal"
          class="p-1 text-sm flex flex-col justify-between text-gray-700 max-h-[300px] overflow-y-scroll origin-top-left bg-white mt-2 px-1 rounded-[8px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div>
            <template v-if="multiple">
              <div class="flex items-center justify-between ml-1">
                <nrjx-input-checkbox
                  name="areaIds"
                  class="mr-2"
                  :disabled="areaDisabled?.includes(rootArea.id)"
                  :model-value="selectedAreas.includes(rootArea.id)"
                  @update:model-value="toggleArea(rootArea.id)"
                />
                <div
                  class="flex cursor-pointer items-center justify-between rounded w-full hover:bg-gray-50 h-[36px] px-1"
                  :class="[
                    rootArea.id === areaId ? 'bg-green-50 border-green-200 text-green-700 border' : '',
                    isAreaDisabled(rootArea.id) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
                  ]"
                >
                  <p class="text-sm whitespace-nowrap">{{ rootArea.name }}</p>
                </div>
              </div>
              <div
                v-if="rootArea?.children.filter((child) => hasEnergyMeter(getArea(child.id))).length > 0"
                class="w-full my-1 h-px bg-gray-200"
              />
              <div
                v-for="children in rootArea?.children.filter((child) => hasEnergyMeter(getArea(child.id)))"
                :key="`area-${children.id}`"
                class="flex items-center justify-between ml-1"
              >
                <nrjx-input-checkbox
                  name="areaIds"
                  class="mr-2"
                  :disabled="areaDisabled?.includes(children.id)"
                  :model-value="selectedAreas.includes(children.id)"
                  @update:model-value="toggleArea(children.id)"
                />
                <div
                  class="flex cursor-pointer items-center justify-between rounded w-full hover:bg-gray-50 h-[36px] pr-2"
                  :class="[
                    subMenus.find((subMenu) => subMenu.id === children.id) ? 'bg-gray-100' : '',
                    children.id === currentArea?.id || getParentsOfSelectedArea.includes(children.id)
                      ? 'bg-green-50 border-green-200 text-green-700 border'
                      : '',
                    props.side === 'left' ? 'flex-row-reverse justify-start' : 'flex-row justify-start',
                  ]"
                  @click="clickItem(children.id)"
                >
                  <ui-icon
                    v-if="getArea(children.id).children.length > 0"
                    :name="props.side === 'left' ? 'ChevronRight' : 'ChevronLeft'"
                    class="w-4"
                    :class="[props.side === 'left' ? 'ml-1' : 'mr-1']"
                  />
                  <div v-else />
                  <p class="text-sm whitespace-nowrap">{{ children.name }}</p>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="p-2 rounded hover:bg-gray-100 select-none"
                :class="[
                  rootArea.id === areaId ? 'bg-green-50 border-green-200 text-green-700 border' : '',
                  isAreaDisabled(rootArea.id) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
                ]"
                @click="clickRootItem(rootArea.id)"
              >
                {{ rootArea.name }}
              </div>
              <div
                v-if="rootArea?.children.filter((child) => hasEnergyMeter(getArea(child.id))).length > 0"
                class="w-full my-1 h-px bg-gray-200"
              />
              <div
                v-for="children in rootArea?.children.filter((child) => hasEnergyMeter(getArea(child.id)))"
                :key="`area-${children.id}`"
                class="flex cursor-pointer items-center rounded hover:bg-gray-100 h-[36px]"
                :class="[
                  getArea(children.id)?.children?.length > 0 ? 'pl-1 pr-2' : 'px-2',
                  subMenus.find((subMenu) => subMenu.id === children.id) ? 'bg-gray-100' : '',
                  children.id === currentArea?.id || getParentsOfSelectedArea.includes(children.id)
                    ? 'bg-green-50 border-green-200 text-green-700 border'
                    : '',
                  props.side === 'left' ? 'flex-row-reverse justify-end' : 'flex-row justify-start',
                ]"
                @click="clickItem(children.id)"
              >
                <ui-icon
                  v-if="getArea(children.id).children.length > 0"
                  :name="props.side === 'left' ? 'ChevronRight' : 'ChevronLeft'"
                  class="w-4"
                  :class="[props.side === 'left' ? 'ml-1' : 'mr-1']"
                />
                <p class="text-sm whitespace-nowrap">{{ children.name }}</p>
              </div>
            </template>
          </div>
          <ui-button v-if="multiple" :color="selectedAreas?.length > 0 ? 'primary' : 'disabled'" class="w-full mt-2" @click="closeMenu()">
            {{ validationButtonText ?? $t('global.validate') }}
          </ui-button>
        </div>

        <!-- SubMenus -->
        <div
          v-show="subMenus.length > 0"
          ref="subMenusContainer"
          class="max-h-[300px] z-[9999] flex gap-2"
          :class="[props.side === 'left' ? 'flex-row' : 'flex-row-reverse']"
        >
          <div
            v-for="subMenu in subMenus"
            :key="`sub-menu-${subMenu.id}`"
            class="bg-white rounded-[8px] mt-2 px-1 p-1 text-sm text-gray-700 max-h-[300px] overflow-x-hidden overflow-y-scroll shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none inline-block whitespace-nowrap"
          >
            <template v-if="multiple">
              <div
                v-for="children in subMenu.children.filter((child) => hasEnergyMeter(getArea(child.id)))"
                :key="`area-${children.id}`"
                class="flex items-center justify-between ml-1"
              >
                <nrjx-input-checkbox
                  class="mr-2"
                  name="areaIds"
                  :disabled="areaDisabled?.includes(children.id)"
                  :model-value="selectedAreas.includes(children.id)"
                  @update:model-value="toggleArea(children.id)"
                />
                <div
                  class="flex cursor-pointer items-center justify-between rounded w-full hover:bg-gray-50 h-[36px] min-w-[100px] px-2"
                  :class="[
                    subMenus.find((subMenu) => subMenu.id === children.id) ? 'bg-gray-100' : '',
                    props.side === 'left' ? 'flex-row-reverse justify-end' : 'flex-row justify-start',
                  ]"
                  @click="clickItem(children.id)"
                >
                  <div v-if="getArea(children.id).children.length > 0">
                    <ui-icon
                      :name="props.side === 'left' ? 'ChevronRight' : 'ChevronLeft'"
                      class="w-4"
                      :class="[props.side === 'left' ? 'ml-1' : 'mr-1']"
                    />
                  </div>

                  <p class="text-sm whitespace-nowrap w-full">{{ children.name }}</p>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="p-2 rounded hover:bg-gray-100"
                :class="[
                  subMenu.id === currentArea?.id ? 'bg-green-50 border-green-200 text-green-700 border' : '',
                  props.areaDisabled?.includes(subMenu.id) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
                ]"
                @click="selectSubMenu(subMenu.id)"
              >
                {{ $t('global.all_areas') }}
              </div>
              <div class="w-full my-1 h-px bg-gray-200" />
              <div
                v-for="children in subMenu.children.filter((child) => hasEnergyMeter(getArea(child.id)))"
                :key="`area-${children.id}`"
                class="flex items-center rounded hover:bg-gray-100 h-[36px] whitespace-nowrap"
                :class="[
                  getArea(children.id).children.length > 0 ? 'pl-1 pr-2' : 'px-2',
                  subMenus.find((subMenu) => subMenu.id === children.id) ? 'bg-gray-100' : '',
                  children.id === currentArea?.id || getParentsOfSelectedArea.includes(children.id)
                    ? 'bg-green-50 border-green-200 text-green-700 border'
                    : '',
                  isAreaDisabled(children.id) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
                ]"
                @click="clickItem(children.id)"
              >
                <ui-icon
                  v-if="getArea(children.id).children.length > 0"
                  :name="props.side === 'left' ? 'ChevronRight' : 'ChevronLeft'"
                  class="mr-1 w-4"
                />
                <p class="text-sm">{{ children.name }}</p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* Personnalisation de la barre de défilement */
::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
  margin: 2px;
}

::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  border-radius: 4px;
  border: 2px solid white;
}

::-webkit-scrollbar-thumb:hover {
  background: #d1d5db;
}
</style>
